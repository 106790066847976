import React, { FC } from 'react';
import { graphql } from 'gatsby';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';

import './MobileAppPromoBanner.scss';
import './MobileAppPromoBannerOverride.scss';

const getRel = (link: UmbracoTypes.TLink): string | undefined =>
  link?.target === '_blank' ? 'noreferrer noopener' : undefined;

const MobileAppPromoBanner: FC<UmbracoTypes.MobileAppPromoBanner> = ({
  appLogo: [appLogoItem],
  ingress,
  appstoreLinks,
}) => {
  const {
    properties: { link: appLogoLink, image: appLogoImage },
  } = appLogoItem;

  return (
    <div className="mobile-app-promo-banner__wrapper">
      <a
        href={extractUrlFromMultiPicker(appLogoLink)}
        target={appLogoLink[0].target}
        rel={getRel(appLogoLink[0])}
      >
        <img
          className="mobile-app-promo-banner__logo"
          src={appLogoImage.url}
          alt={appLogoLink[0].name}
        />
      </a>
      <p className="mobile-app-promo-banner__ingress">{ingress}</p>
      <div className="mobile-app-promo-banner__links">
        {appstoreLinks.map(({ properties: { link, image } }) => {
          const url = extractUrlFromMultiPicker(link);

          return (
            <a key={url} href={url} target={link[0].target} rel={getRel(link[0])}>
              <img
                className="mobile-app-promo-banner__appstore-logo"
                src={image.url}
                alt={link[0].name}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default MobileAppPromoBanner;

export const query = graphql`
  fragment FragmentMobileAppPromoBanner on MOBILE_APP_PROMO_BANNER {
    appLogo {
      properties {
        image {
          url
        }
        link {
          name
          target
          url
        }
      }
    }
    appstoreLinks {
      properties {
        image {
          url
        }
        link {
          name
          target
          url
        }
      }
    }
    ingress
  }
`;
