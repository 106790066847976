import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import HeaderLogo from 'components/Header/HeaderLogo';
import HeaderNavigation from 'components/Header/HeaderNavigation';
import HeaderSearch from 'components/Header/HeaderSearch';
import HeaderSearchToggler from 'components/Header/HeaderSearchToggler';
import SocialRedirection from 'components/SocialRedirection';
import getParsedConnectBlockMediaLinks from 'components/ConnectBlock/parsers';
import RedirectionModal from 'components/RedirectionModal';
import LanguageSelector from 'components/LanguageSelector';

import useModal from 'hooks/useModal';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { THandleVisibilityEventType, IHeaderProps } from './model';

import './Header.scss';

const Header: FC<IHeaderProps> = (props): ReactElement => {
  const [activeMainCategory, setActiveMainCategory] = useState<string | null>(null);
  const [activeSubCategory, setActiveSubCategory] = useState<string | null>(null);
  const [isSearchFieldVisible, setSearchFieldVisible] = useState<boolean>(false);
  const [isOpen, setValue] = useState(false);
  const [
    lastEventTypeForMainCategory,
    setLastEventTypeForMainCategory,
  ] = useState<THandleVisibilityEventType | null>(null);
  const [activeMediaLink, setActiveMediaLink] = useState<string | null>(null);

  const { isMiddleTablet, isMobile } = useScreenRecognition();

  const {
    isVisibleModal: isVisibleRedirectionModal,
    openModal: openRedirectionModal,
    closeModal: closeRedirectionModal,
  } = useModal(false);

  const {
    navItems,
    headerLogo,
    headerLogoAriaLabel,
    headerLogoLink,
    headerLogoTitle,
    inlineStylesForCovidBanner,
    mediaLinks,
    hideSocialMediaBlock,
    socialRedirectionBlock,
    searchButtonAndLabelTexts,
    headerMobileOpener,
    searchPath,
    langSelector,
    marketName,
    sectionNavLandmark,
    navigationButtonAriaLabel,
  } = props;

  const handleSearchFieldVisibility = useCallback(() => {
    setSearchFieldVisible((oldValue: boolean) => !oldValue);
  }, []);

  useEffect(() => {
    if (isSearchFieldVisible) {
      window.addEventListener('scroll', handleSearchFieldVisibility);
    } else {
      window.removeEventListener('scroll', handleSearchFieldVisibility);
    }

    return () => {
      window.removeEventListener('scroll', handleSearchFieldVisibility);
    };
  }, [isSearchFieldVisible]);

  const handleMainCategoryVisibility = useCallback(
    (id: string | null, type: THandleVisibilityEventType) => () => {
      setLastEventTypeForMainCategory(type);

      /**
       * this is a fix for the accessability issue when a submenu dropdown is opened by tab
       * and cursor is in the area of the submenu
       * */
      if (type === 'onmouseenter' && lastEventTypeForMainCategory === 'onclick') {
        return;
      }

      const activeId = id === activeMainCategory ? null : id;
      setActiveMainCategory(activeId);
      if (!activeId) {
        setActiveSubCategory(null);
      }
    },
    [activeMainCategory, lastEventTypeForMainCategory]
  );

  const handleSubCategoryVisibility = useCallback(
    (subCategoryId: string) => () => {
      setActiveSubCategory(subCategoryId === activeSubCategory ? null : subCategoryId);
    },
    [activeSubCategory]
  );

  const handleNavMenuVisibility = useCallback(() => {
    setValue((oldValue) => !oldValue);
    setActiveMainCategory(null);
    setActiveSubCategory(null);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
  }, [isOpen]);

  const handleOpenRedirectionModal = useCallback(
    (mediaLink: string) => () => {
      setActiveMediaLink(mediaLink);
      openRedirectionModal();
    },
    []
  );

  const handleCloseRedirectionModal = useCallback(() => {
    closeRedirectionModal();
    setActiveMediaLink(null);
  }, []);

  const redirectionModalsMap: { [key: string]: SocialRedirection.IUmbracoModal } = useMemo(() => {
    const response = {};

    if (!socialRedirectionBlock?.[0].properties?.socialRedirectionModals.length) {
      return response;
    }

    socialRedirectionBlock[0].properties.socialRedirectionModals.forEach((item) => {
      response[item.properties.socialRedirectionModalMediaLinkTarget] = { ...item.properties };
    });

    return response;
  }, [socialRedirectionBlock?.[0].properties?.socialRedirectionModals]);

  const socialRedirectionLinks = getParsedConnectBlockMediaLinks(
    socialRedirectionBlock?.[0]?.properties?.socialRedirectionLinks
  );

  return (
    <header
      className={classNames('header', { 'nav-active': isOpen })}
      style={inlineStylesForCovidBanner}
      id="header"
    >
      <Container fluid className="header-container">
        <HeaderLogo
          logo={headerLogo}
          headerLogoAriaLabel={headerLogoAriaLabel}
          headerLogoLink={headerLogoLink}
          headerLogoTitle={headerLogoTitle}
        />
        <div className="header-navigation-holder">
          <HeaderNavigation
            activeMainCategory={activeMainCategory}
            activeSubCategory={activeSubCategory}
            handleMainCategoryVisibility={handleMainCategoryVisibility}
            handleSubCategoryVisibility={handleSubCategoryVisibility}
            navItems={navItems}
            mediaLinks={mediaLinks}
            hideSocialMediaBlock={hideSocialMediaBlock}
            handleOpenRedirectionModal={handleOpenRedirectionModal}
            socialRedirectionLinks={socialRedirectionLinks}
            langSelector={langSelector}
            marketName={marketName}
            ariaLabel={sectionNavLandmark}
          />
          <HeaderSearchToggler handleSearchFieldVisibility={handleSearchFieldVisibility} />
          <button
            type="button"
            className="nav-opener"
            aria-label={navigationButtonAriaLabel}
            onClick={handleNavMenuVisibility}
          >
            <span className="accessibility-hidden">{headerMobileOpener}</span>
          </button>
          {socialRedirectionBlock?.[0].properties?.socialRedirectionLinks.length &&
          socialRedirectionBlock?.[0].properties?.socialRedirectionModals.length ? (
            <span className="social-redirection-triggers-block">
              <span className="divider">|</span>
              <SocialRedirection
                links={socialRedirectionLinks}
                handleOpenModal={handleOpenRedirectionModal}
              />
            </span>
          ) : null}
          {!(isMiddleTablet || isMobile) && langSelector && (
            <LanguageSelector
              title={langSelector.langSelectorTitle}
              opener={{
                mob: langSelector.selected.langTitle,
                desktop: langSelector.selected.langTitleShort,
              }}
              languages={langSelector.languages}
            />
          )}
        </div>
      </Container>
      <Container className={`nf-header-search-container ${isSearchFieldVisible ? 'visible' : ''}`}>
        {searchButtonAndLabelTexts?.length > 0 && (
          <HeaderSearch
            isSearchFieldVisible={isSearchFieldVisible}
            handleSearchFieldVisibility={handleSearchFieldVisibility}
            searchButtonText={searchButtonAndLabelTexts[0]?.properties.searchButtonText}
            searchInputPlaceholder={searchButtonAndLabelTexts[0]?.properties.searchInputPlaceholder}
            searchPath={searchPath}
          />
        )}
      </Container>
      {activeMediaLink && redirectionModalsMap[activeMediaLink] ? (
        <RedirectionModal
          messageIconAlt={activeMediaLink}
          isVisible={isVisibleRedirectionModal}
          handleClose={handleCloseRedirectionModal}
          closeBtnTitle={redirectionModalsMap[activeMediaLink].socialRedirectionModalCloseTitle}
          message={redirectionModalsMap[activeMediaLink].socialRedirectionModalMessage}
          messageIcon={redirectionModalsMap[activeMediaLink].socialRedirectionModalMessageIcon}
          redirectCTA={
            redirectionModalsMap[activeMediaLink].socialRedirectionModalRedirectCTA?.[0]?.properties
          }
          stayCTA={
            redirectionModalsMap[activeMediaLink].socialRedirectionModalStayCTA?.[0]?.properties
          }
        />
      ) : null}
    </header>
  );
};

export const query = graphql`
  fragment FragmentHeader on IHeader {
    sectionNavLandmark
    headerLogo {
      fallbackUrl
      svg {
        content
      }
    }
    navItems {
      properties {
        items {
          properties {
            dropdown {
              properties {
                simpleLink {
                  name
                  url
                }
              }
            }
            title
          }
        }
        simpleLink {
          name
          url
        }
        title
        titleLink {
          url
          name
        }
      }
      structure
    }
    navigationButtonAriaLabel
    hideSocialMediaBlock
    headerLogoTitle
    socialRedirectionBlock {
      ...FragmentSocialRedirection
    }
    searchButtonAndLabelTexts {
      properties {
        searchButtonText
        searchInputPlaceholder
      }
    }
    headerMobileOpener
  }
`;

export default Header;
